//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import axios from 'axios'
import { defineStore } from 'pinia'

export const useAnimalTypesStore = defineStore('AnimalTypesStore', {
  state: () => ({
    AnimalTypes: [] as any[],
    AnimalTypesIncVirtual: [] as any[],
    AnimalTypesProduction: [] as any[]
  }),
  actions: {
    async fetchAnimalTypes() {
      if (this.AnimalTypes.length === 0) {
        const result = await axios.get(apiURL + '/animalTypes')
        this.AnimalTypes = result.data
      }
    },
    async fetchAnimalTypesIncVirtual() {
      if (this.AnimalTypesIncVirtual.length == 0) {
        const result = await axios.get(apiURL + '/animalTypes?&includeVirtual=true')
        this.AnimalTypesIncVirtual = result.data
      }
    },
    async fetchAnimalTypesProduction() {
      if (this.AnimalTypesProduction.length === 0) {
        const result = await axios.get(apiURL + '/animalTypes/production')
        this.AnimalTypesProduction = result.data
      }
    }
  }
})
